import '../css/TheTeam.css';
// @ts-ignore
import ahmed from "../assets/ahmed_cropped.jpeg";
// @ts-ignore
import david from "../assets/david_cropped.jpg";
import { useEffect } from 'react';

interface TheTeamProps {

}

export const TheTeam: React.FC<TheTeamProps> = ({  }) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div className="the-team">
            <div className="the-team__heading heading--1">
                The Team
            </div>
            <div className="the-team__container">

                <div className="the-team__row ahmed--background">
                    <img className="the-team__selfie" src={ahmed} />
                    <div className="the-team__details">
                        <div className="the-team__name">
                            Ahmed Minhaj
                        </div>
                        <div className="the-team__bio medium--text">
                        Meet Ahmed, a software engineer who combines his proficiency in automating business processes with a passion for building AI-based products. His expertise extends to setting up efficient digital workflows that have dramatically enhanced productivity by up to 90% in previous projects. His work not only optimizes operations but also innovates solutions that can help businesses to grow.
                        </div> 
                    </div>
                </div>

                <div className="the-team__row david--background">
                    <div className="the-team__details">
                        <div className="the-team__name">
                            David Pogosian
                        </div>
                        <div className="the-team__bio medium--text">
                            David has a long history of building awesome web applications and user interfaces. He has a lot of experience in various UI/UX frameworks and backend technologies. He prioritzes maintainability and quality of code, which in turn, reduces development time, and elevates product quality.
                        </div> 
                    </div>
                    <img className="the-team__selfie" src={david} />
                </div>
            </div>
        </div>
    )
};