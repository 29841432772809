import "../css/RouteContainer.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Navigation } from './Navigation';
import { Home } from './Home';
import { Contact } from './Contact';
import { Services } from './Services';
import { Testimonials } from './Testimonials';
import { TheTeam } from './TheTeam';
import { Footer } from "./Footer";

interface RouteContainerProps {

}

export const RouteContainer: React.FC<RouteContainerProps> = ({  }) => {
    return (
        <Router>
            <Navigation />
            <div className="content">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/theteam" element={<TheTeam />} />
                    {/* <Route path="/testimonials" element={<Testimonials />} /> */}
                    <Route path="/contact" element={<Contact />} />
                    {/* <Route path="*" element={<Navigate to="/" />} /> */}
                </Routes>
            </div>
            <Footer />
        </Router>
    );
};