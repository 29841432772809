import './css/App.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { RouteContainer } from './components/RouteContainer';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBrEIFoZHCjaeSbJf-tK4W2lGDPAVhwzq0",
    authDomain: "techgroup313.firebaseapp.com",
    projectId: "techgroup313",
    storageBucket: "techgroup313.appspot.com",
    messagingSenderId: "22157056221",
    appId: "1:22157056221:web:42b00a9de1561802f16334",
    measurementId: "G-LC3121F7VW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function App() {
    return (
        <RouteContainer />
    );
}

export default App;
