import '../css/Navigation.css';
// @ts-ignore
import logo from "../assets/313techgroup_logo.png";
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';


interface NavigationProps {

}

export const Navigation: React.FC<NavigationProps> = ({  }) => {
    const [navigationVisibility, setNavigationVisibility] = useState(true);
    const [navigationExpansion, setNavigationExpansion] = useState(false);
    const location = useLocation();

    function toggleHamburger() {
        setNavigationVisibility(!navigationVisibility);
        setNavigationExpansion(!navigationExpansion);
    }

    function followLink() {
        if (window.innerWidth <= 800) {
            setTimeout(
                toggleHamburger,
                300
            );
        }
    }

    return (
        <div id="navigation" className={`navigation ${navigationExpansion ? "navigation--expansion" : ""}`}>
            <Link to="/">
                <img className="navigation__logo" src={logo} />
            </Link>
            <div id="navigation__link__container" className={`navigation__link__container ${navigationVisibility ? "navigation--visibility" : ""}`}>
                <Link onClick={followLink} className={`navigation__link ${location.pathname === "/services" && "navigation__link--active"}`} to="/services">Services</Link>
                <Link onClick={followLink} className={`navigation__link ${location.pathname === "/theteam" && "navigation__link--active"}`} to="/theteam">The Team</Link>
                {/* <Link className={`navigation__link ${location.pathname === "/testimonials" && "navigation__link--active"}`} to="/testimonials">Testimonials</Link> */}
                <Link onClick={followLink} className={`navigation__link ${location.pathname === "/contact" && "navigation__link--active"}`} to="/contact">Contact</Link>
            </div>
            <button className="navigation__hamburger" onClick={toggleHamburger}>
                <i className="bi bi-list"></i>
            </button>
        </div>
    );
};
