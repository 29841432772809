import React, { useEffect, useState } from 'react';
import '../css/Contact.css';

export const Contact: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSend = async () => {
        if (!formData.name) {
            alert("Please fill in your name.");
            return;
        }
        if (!formData.email) {
            alert("Please fill in your email address.");
            return;
        }
        if (!formData.message) {
            alert("Please fill in your message.");
            return;
        }

        try {
            const response = await fetch('https://davidp.pythonanywhere.com/tech_group_form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                setIsSubmitted(true);
            } else {
                throw new Error('Failed to send message.');
            }
        } catch (error) {
            alert((error as Error).message);
        }
    };

    return (
        <div className="contact">
            <div className="contact__heading heading--1">Contact Us</div>
            <div className={`contact__form__container ${isSubmitted ? "hide--form" : ""}`}>
                <div className="contact__form__row name--row">
                    <input 
                        id="name"
                        className="contact__form__name medium--text"
                        name="name"
                        type="text"
                        placeholder="Name"
                        onChange={handleChange}
                    />
                </div>
                
                <div className="contact__form__row">
                    <input 
                        id="email"
                        className="contact__form__email medium--text"
                        name="email"
                        type="text"
                        placeholder="Email"
                        onChange={handleChange} 
                    />
                </div>

                <div className="contact__form__row message--row">
                    <textarea 
                        id="message" 
                        className="contact__form__message medium--text"
                        name="message"
                        placeholder="Message"
                        onChange={handleChange}
                    />
                </div>
                
                <div className="contact__form__row send--row">
                    <button 
                        onClick={handleSend}
                        className="contact__form__send"
                    >
                        Send
                    </button>
                </div>
            </div>
            <div className={`contact__success-message medium--text ${isSubmitted ? "show--message" : ""}`}>
                Thank you for your message! <br/>We will get back to you soon.
            </div>
        </div>
    );
};
