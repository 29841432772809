import React, { useState, useEffect } from 'react';
import '../css/Home.css';
import { Link } from 'react-router-dom';

interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
    const [animationClass, setAnimationClass] = useState("roll--initial");
    const [serviceIndex, setServiceIndex] = useState(0);

    const services = [
        "Custom Websites",  
        "Inventory Systems", 
        "CRM Systems", 
        "E-commerce Solutions", 
        "Data Analysis", 
        "Marketing Campaigns", 
        "SEO"
    ];

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        
        function animate() {
            setAnimationClass("roll--animation-1");
            setTimeout(() => {
                setAnimationClass("roll--animation-2");
                setTimeout(() => {
                    setAnimationClass("roll--hide");
                }, 1000);
            }, 4000);
            setServiceIndex(previousIndex => (previousIndex + 1) % services.length)
        }

        animate();

        const intervalId = setInterval(() => {
            animate();
        }, 6000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="home">
            <div className="home__showstopper">
                <div className="home__showstopper__top-pad"></div>
                
                <div className="home__showstopper__content">
                    <div className="home__showstopper__static-text heading--1">
                        We can get you
                    </div>
                    <div className={`home__showstopper__dynamic-text heading--1 ${animationClass}`}>
                        {services[serviceIndex]}
                    </div>
                </div>
                
                <div className="home__showstopper__bottom-pad"></div>
            </div>

            <div className="home__description large--text">
                <div className="home__description__greeting">
                    Hi there,
                </div>
                <div className="home__description__content">
                    We are a Detroit-based technology group specialized in providing small businesses with digital solutions.
                </div>
                <Link className="home__description__link" to="/services">
                    See what we can do for you.
                </Link>
            </div>
        </div>
    )
};
