import '../css/Footer.css';

interface FooterProps {

}

export const Footer: React.FC<FooterProps> = ({  }) => {
    return (
        <div className="footer">
            <div className="footer__text">
                313TechGroup
            </div>
        </div>
    )
};