import { Link } from 'react-router-dom';
import '../css/Services.css';
import { useEffect } from 'react';

interface ServicesProps {

}

export const Services: React.FC<ServicesProps> = ({  }) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);
    
    return (
        <div className="services">
            <div className="services__heading heading--1">
                Services
            </div>
            
            <div className="services__list">
                <div className="services__list__item">
                    <i className="services__list__item__emoji bi bi-card-heading"></i>
                    <div className="services__list__item__name large--text">
                        Custom Websites
                    </div>

                    <div className="services__list__item__description medium--text">
                        We build beautifully styled websites for our partners and place a special emphasis on maintainability, expandability, costs mitigation. 
                    </div>
                </div>

                <div className="services__list__item">
                    <i className="services__list__item__emoji bi bi-box-seam"></i>
                    <div className="services__list__item__name large--text">
                        Inventory Systems
                    </div>

                    <div className="services__list__item__description medium--text">
                        Our custom Inventory Systems are built from the ground up with our partners in mind. That way, our system always integrate seamlessly with existing workflows. 
                    </div>
                </div>

                <div className="services__list__item">
                    <i className="services__list__item__emoji bi bi-people-fill"></i>
                    <div className="services__list__item__name large--text">
                        CRM Systems
                    </div>

                    <div className="services__list__item__description medium--text">
                        We know how important it is to have a highly intuitive, scalable, and insightful Customer Relationship Management system. That is why our partners get a custom system that fits their requirements accurately.
                    </div>
                </div>

                <div className="services__list__item">
                    <i className="services__list__item__emoji bi bi-cart3"></i>
                    <div className="services__list__item__name large--text">
                        E-Commerce Solutions
                    </div>

                    <div className="services__list__item__description medium--text">
                        Selling online can be a confusing and unpleasant experience. We work with our partners to set up online stores that boast enterprise-grade security without the hefty fees imposed by large corporations.
                    </div>
                </div>

                <div className="services__list__item">
                    <i className="services__list__item__emoji bi bi-graph-up"></i>
                    <div className="services__list__item__name large--text">
                        Data Analysis
                    </div>

                    <div className="services__list__item__description medium--text">
                        Insights gained from data analysis can help businesses identify what works for the customers and what doesn't. We provide our partners with custom-built data collection software, and customizable analytic dashboards adaptable for wherever they are needed. 
                    </div>
                </div>

                <div className="services__list__item">
                    <i className="services__list__item__emoji bi bi-megaphone"></i>
                    <div className="services__list__item__name large--text">
                        Marketing Campaigns
                    </div>

                    <div className="services__list__item__description medium--text">
                        We orchestrate targeted advertisement campaigns for our partners on various social media sites.
                    </div>
                </div>

                <div className="services__list__item">
                    <i className="services__list__item__emoji bi bi-search"></i>
                    <div className="services__list__item__name large--text">
                        SEO
                    </div>

                    <div className="services__list__item__description medium--text">
                        The importance of being discoverable as a business cannot be understated. Our SEO services help our partners get their business in front of a wider customer audience.
                    </div>
                </div>
            </div>

            <Link className="services__get-in-touch" to="/contact">
                Get in touch
            </Link>
        </div>
    )
};